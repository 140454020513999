.landing-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  background-color: #fcd24a;
  position: relative;
  min-height: 100vh;
}

.landing-page-footer {
  flex-direction: row;
  text-align:right;
  width:150px;
  display:flex;
  position: absolute;
  bottom: 0;
  right:0;
  margin-right:10px;
}

.landing-page-image {
  max-width: 30%;
  max-height: 5%;
}

.landing-page-content {
  max-width: 50%;
}

.landing-page-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
}
.policy-link {
  /*background-color:red;*/
  margin-left:20px;
  margin-bottom:5px;
  margin-right:0px;


}
.landing-page-description {
  font-size: 1.25rem;
  margin-bottom: 40px;
}

.landing-page-link {
  font-size: 0.75rem;
  text-decoration: none;
}

.landing-page-link:hover {
  text-decoration: underline;
}
